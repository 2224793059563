<template>
	<div class="pt-5">
		<b-row class="text-center">
			<template v-for="(item, index) in navLinks">
				<!-- {{item.access}} -->
				<b-col
					v-if="item.access"
					cols="6"
					md="3"
					lg="2"
					:key="index"
					class="d-flex justify-content-center pb-5"
				>
					<!-- 10 -->
					<router-link :to="{ name: item.path }">
						<b-card class="clickable card-responsive background-icons-module" body-class="pl-0 pr-0">
							<div class="d-flex justify-content-center">
								<feather-icon
									size="70"
									class="w-90"
									:icon="item.icon"
									v-if="
										item.title != 'Pollos' &&
										item.title != 'Ejemplares' &&
										item.title != 'Encaste' &&
										item.title != 'Enfermeria'
									"
								/>
								<img
									src="@/assets/images/icons/pollo_b11.png"
									alt=""
									v-if="item.title == 'Pollos'"
									class="w-90 filter-image-menu"
									style="width: 70px"
								/>
								<img
									src="@/assets/images/icons/iconos-02.png"
									alt=""
									v-if="item.title == 'Ejemplares'"
									class="w-90 filter-image-menu"
									style="width: 70px"
								/>
								<img
									src="@/assets/images/icons/iconos-03.png"
									alt=""
									v-if="item.title == 'Encaste'"
									class="w-90 filter-image-menu"
									style="width: 70px"
								/>

								<img
									src="/training/icon.svg"
									alt=""
									v-if="item.title == 'Preparacion'"
									class="w-90 filter-image-menu"
									style="width: 70px"
								/>

								<img
									src="@/assets/images/icons/nursery.svg"
									alt=""
									v-if="item.title == 'Enfermeria'"
									class="w-90 filter-image-menu"
									style="width: 70px"
								/>

								<!-- <IconTraining v-if="item.title == 'Preparacion'" /> -->
							</div>
							<h6 class="mt-1" style="font-size: 0.9rem">{{ item.title }}</h6>
							<div v-if="item.counter > 0" class="counter">
								<p>{{ item.counter }}</p>
							</div>
						</b-card>
					</router-link>
				</b-col>
			</template>
		</b-row>

		<!-- <CombinationList/> -->

		<!-- <NetworkSpeed></NetworkSpeed> -->

		<images-sliders-total />

		<loading-in-the-background />
		<!-- <IconTraining/> -->
	</div>
</template>

<script>
import ImagesSlidersTotal from "../amg/header-bookmarks/ImagesSlidersTotal.vue"
import LoadingInTheBackground from "../amg/header-bookmarks/LoadingInTheBackground.vue"
import NetworkSpeed from "./NetworkSpeed.vue"
import { mapState } from "vuex"
import orderService from "@/services/order.service"
import nurseryService from "@/services/nursery/default.service"
import CombinationList from "./snoods/combinations/CombinationList.vue"
import IconTraining from "./IconTrainig.vue"
import store from "@/store"

export default {
	components: {
		ImagesSlidersTotal,
		LoadingInTheBackground,
		NetworkSpeed,
		CombinationList,
		IconTraining,
	},
	data() {
		return {
			menuHidden: this.$store.state.appConfig.layout.menu.hidden,
			navbarConfig: this.$store.state.appConfig.layout.navbar.type,
			navLinks: [
				{
					title: "Usuarios",
					icon: "UserIcon",
					path: "users-amg",
					roles: [1],
					counter: 0,
				},
				{
					title: "Ejemplares",
					icon: "/images/icon-menu/ejemplares.png",
					path: "ejemplares-sr",
					roles: [1, 4, 6, 7],
					counter: 0,
				},
				{
					title: "Encaste",
					icon: "/images/icon-menu/encaste.png",
					path: "dashboard-encaste-sr",
					roles: [1, 4, 6],
					counter: 0,
				},
				{
					title: "Preparacion",
					icon: "/training/icon.svg",
					path: "preparacion-sr",
					roles: [1, 5, 7],
					counter: 0,
				},
				{
					title: "Administrativo",
					icon: "ArchiveIcon",
					path: "management-petty-cash",
					roles: [1, 2],
				},
				{
					title: "Videoteca",
					icon: "FilmIcon",
					path: "videolibrary-specimens-sr",
					roles: [1, 4, 6],
					counter: 0,
				},
				{
					title: "Torneos",
					icon: "AwardIcon",
					path: "tournaments",
					roles: [1, 4, 6],
					counter: 0,
				},
				// {
				// 	title: "Reportes",
				// 	icon: "BookOpenIcon",
				// 	path: "reports-results",
				// 	roles: [1, 4, 6],
				// 	counter: 0,
				// },
				{
					title: "Compras",
					icon: "ShoppingBagIcon",
					path: "orders-list-sr",
					roles: [1, 2],
					counter: 0,
				},
				{
					title: "Inventario",
					icon: "ArchiveIcon",
					path: "inventory-specimens-list-sr",
					roles: [1, 2],
					counter: 0,
				},
				{
					title: "Enfermeria",
					icon: "HeartIcon",
					path: "nursery-sr",
					roles: [1, 2, 12],
					counter: 0,
				},
				{
					title: "Cerebro",
					icon: "ApertureIcon",
					path: "brain-menu",
					roles: [1, 2],
					counter: 0,
				},
			],
		}
	},
	computed: {
		...mapState("header_book", ["urlBackendNode"]),
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
		userModules() {
			return this.$store.getters["auth/currentUser"].moduls
		},
	},

	async created() {
		this.isPreloading()
		await store.dispatch("video/INIT_PLAYRATES")
		const { data } = await orderService.getCounterOrder()

		const totalOrders = data.reduce(function (previousValue, currentValue) {
			return previousValue + (currentValue.show == 1 ? parseInt(currentValue.count) : 0)
		}, 0)

		//
		const counterNursery = await nurseryService.getMenuCounter()

		this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true)
		this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "floating" })

		this.navLinks = this.navLinks.map((it) => {
			let counter = it.counter
			switch (it.path) {
				case "orders-list-sr":
					counter = totalOrders
					break
				case "nursery-sr":
					counter = counterNursery.data.counter
					break
			}
			return {
				...it,
				counter,
			}
		})

		const {
			roles,
			privileges: [privilegio],
		} = this.$store.state.auth.currentUser


		this.navLinks = this.navLinks.map((it) => {
			let access = false
			it.roles.forEach((rol) => {
				roles.forEach((rolRoute) => {
					if (rol == rolRoute) {
						access = true
					}
					if (it.path == "orders-list-sr" && privilegio == 1) {
						access = true
					}
				})
			})
			return {
				...it,
				access: access,
			}
		})
	},
	destroyed() {
		this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
			type: this.navbarConfig,
		})
		this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden)
		this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical")
	},
}
</script>

<style scoped lang="scss">
.filter-image-menu {
	filter: brightness(0) saturate(100%) invert(43%) sepia(26%) saturate(2015%) hue-rotate(212deg) brightness(96%)
		contrast(96%);
}
.counter {
	position: absolute;
	top: -8px;
	right: -8px;
	background: red;
	width: 24px;
	height: 24px;
	border-radius: 50% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	p {
		color: #fff;
		font-weight: bold;
		margin-bottom: 0;
	}
}
</style>
